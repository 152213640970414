<template>
  <ReportsPaginated
    titleKey="report_user_permissions_title"
    :companyView="companyView"
    :getterUrl="getterUrl"
    :userEmail="userEmail"
    @tableOptions="tableOptions = $event"
    :contexts="['add_admin_permission', 'remove_admin_permission']"
  />
</template>
<script>
import ReportsPaginated from "@/components/logs/ReportsPaginated.vue";

export default {
  name: "ReportsUserPermissions",
  components: { ReportsPaginated },
  props: {
    userEmail: { type: String },
    companyView: { type: Boolean, default: () => true },
  },
  data: () => {
    return {
      getterUrl: `${process.env.VUE_APP_API_BASE_URL}/logs/users`,
    };
  },
};
</script>
